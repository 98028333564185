import "./App.css";

function App() {
  return (
    <div className="App">
      <h1> Lets start, build new world !!!</h1>
      <h2>This site is underconstruction and never will be finished.</h2>
      <h3>
        Technologies to practice with it is React, Azure Web Static, Azure
        function app
      </h3>
      <p>Today I will publish to itpuzzle.net</p>
      <p>
        Configure DNS with Azure web static Custom domain and Squarespace as a
        Domain holder, and of course with GitHub.
      </p>
      <p>
        Also is important to say, that I using any help I can: AI(ChatGPT4,
        Copilot) and I love it and IT.
      </p>
      <p>Thanks to all.</p>
      <h1>If you want to practice with me just let me know!!! </h1>
      <h2>lucas7602@gmail.com</h2>
    </div>
  );
}

export default App;
